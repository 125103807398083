import { useState } from "react";
import { Buffer } from "buffer";
import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { ClipLoader } from "react-spinners";
import Barcode from "react-barcode";
import $ from "jquery";
import uuid from "react-uuid";
import { useSearchParams } from "react-router-dom";

import { defaultProfilePicture } from "#images/defaultProfilePicture";
import { APULogoObject } from "#components/APULogo";
import OopsPage from "#components/OopsPage";
import DisplayCard from "#services/one-card/DisplayCard";
import GradIcon from "#images/icons/icon-grad-circle.png";
import iconRotate from "#images/icons/icon-rotate.svg";
import "./VIDOneCard.css";
import { apiURL } from "#components/ApiUrlConverter";

// Note, we will need to look up detailed OneCard Information As well
const endpoint = apiURL("/api/v1/users");

interface UserDataType {
  userData: {
    data: {
      NAME: string;
      OPRID: string;
      EMPLID: string;
      PERSONAS: string;
      ACAD_CAREER_DESCR: string;
      ACAD_PLANS_DESCR: string;
      ALUMNI: string;
      jobtitle: string;
      department: string;
      onecardNumber: string;
    };
  };
}

interface AxiosUserDataType extends UserDataType {
  userIsSuccess: boolean;
  profilePicture: string;
}

const AffilitationSection = (props: UserDataType) => {
  // If the user is a faculty and staff, return both of those
  if (
    props.userData?.data.PERSONAS.indexOf("FAC") >= 0 &&
    props.userData?.data.PERSONAS.indexOf("STAFF") >= 0
  ) {
    return <p id="career">Faculty and Staff</p>;
  }
  // If the user is a faculty, show that
  else if (props.userData?.data.PERSONAS.indexOf("FAC") >= 0) {
    return <p id="career">Faculty</p>;
  }
  // If the user is a staff, show that
  else if (props.userData?.data.PERSONAS.indexOf("STAFF") >= 0) {
    return <p id="career">Staff</p>;
  }
  // Fall back if a user is an employee but missing specific Fac or Staff status
  else if (props.userData?.data.PERSONAS.indexOf("EMP") >= 0) {
    return <p id="career">Faculty/Staff</p>;
  }
  // If the user has a career, return the descr of that career plus "student"
  else if (props.userData?.data.ACAD_CAREER_DESCR) {
    return <p id="career">{props.userData.data.ACAD_CAREER_DESCR} Student</p>;
  }
  // Otherwise, leave the section blank (this really shouldn't happen)
  else {
    return <></>;
  }
};

const StudentSection = (props: UserDataType) => {
  // First, if the user doesn't have any academic plan, leave this blank
  if (!props.userData?.data.ACAD_PLANS_DESCR) {
    return <></>;
  }
  // Now, we can assume the user does have a major, so we can go from here
  else {
    // First, since a student can potentially have multiple plans, aka Majors and Minors
    // we split the comma delinated list into an array.
    const planDescrs = props.userData.data.ACAD_PLANS_DESCR.split(",");
    // Now, return the academic plans section
    return (
      <>
        <div className="titles">
          <div className="title">Academics</div>
          {props.userData.data.ALUMNI === "Y" && (
            <div className="title" id="alumni-title">
              Alumni
            </div>
          )}
        </div>
        <div className="sub-titles">
          {planDescrs.map((descr: string) => (
            <p className="sub-title" key={uuid()}>
              {descr}
            </p>
          ))}
        </div>
      </>
    );
  }
};

const EmployeeSection = (props: UserDataType) => {
  // First, if the user doesn't have a job title, leave this blank
  if (!props.userData?.data.jobtitle) {
    return <></>;
  }
  // Now, we can assume the user is an employee, so print their job information
  else {
    return (
      <>
        <div className="titles">
          <div className="title">Employment</div>
          {props.userData.data.ALUMNI === "Y" && (
            <div className="title" id="alumni-title">
              Alumni
            </div>
          )}
        </div>
        <div className="sub-titles">
          <p className="sub-title">{props.userData.data.jobtitle}</p>
          <p className="sub-title">{props.userData.data.department}</p>
        </div>
      </>
    );
  }
};

const OneCardBarcode = (props: UserDataType) => {
  // First, if there is no onecard number available, return nothing
  if (!props.userData?.data.onecardNumber) {
    return <></>;
  }
  // If the barcode is defined, return that section
  else {
    return (
      <Barcode
        value={props.userData.data.onecardNumber}
        format="CODE39"
        displayValue={false}
        width={2}
        height={60}
      />
    );
  }
};

const CardFront = (props: AxiosUserDataType) => {
  // Else, if the data was fetched successfully, construct and return the card front
  if (props.userIsSuccess) {
    return (
      <>
        <div id="upper">
          <img id="apu-logo" alt="APU Logo" src={APULogoObject.hzPNG} />
          {props.userData.data.ALUMNI === "Y" ? (
            <img id="alumn-icon" alt="Alumni Icon" src={GradIcon} />
          ) : (
            <></>
          )}
          <img id="profile-picture" alt="Profile" src={props.profilePicture} />
          <div id="name-section">
            <p id="name">
              {props.userData.data.NAME}
              {/* {props.userData.data.ALUMNI === "Y" && " - Alumni"} */}
            </p>
            <p id="netid">{props.userData.data.OPRID}</p>
            <AffilitationSection userData={props.userData} />
          </div>
        </div>
        <div id="lower">
          {props.userData.data?.jobtitle ? (
            <EmployeeSection userData={props.userData} />
          ) : (
            <StudentSection userData={props.userData} />
          )}
          <div id="barcode-section">
            <p id="emplid">ID: {props.userData.data.EMPLID}</p>
            <div id="barcode">
              <OneCardBarcode userData={props.userData} />
            </div>
          </div>
        </div>
      </>
    );
  }
  // Otherwise, the card is still loading, so return a loading spinner
  else {
    return (
      <div className="card-face" id="front">
        <ClipLoader color={"#700"} size={50} />
      </div>
    );
  }
};

// Toggle Modal dining Hours
const showHours = (status: boolean) => {
  const hoursModal = document.getElementById("hours-modal");
  const modalBackground = document.getElementById("modal-background");
  const modalStatus = status === true ? "block" : "none";
  if (hoursModal) hoursModal.style.display = modalStatus;
  if (modalBackground) modalBackground.style.display = modalStatus;
};

const VIDCard = () => {
  // Get url params
  const [page, ,] = useSearchParams();
  const devMode = Boolean(page.get("dev") ?? false);
  const mealBal = Number(page.get("meal"));
  const guestBal = Number(page.get("guest"));
  const cougBuck = Number(page.get("bucks"));

  let displayBack;
  const endpointApi =
    devMode === true ? endpoint.replace(/v1/g, "public") : endpoint;

  const [profilePicture, setProfilePicture] = useState(defaultProfilePicture);
  const [hasAccount, setHasAccount] = useState(false);
  const [hasDiningPlan, setHasDiningPlan] = useState(false);
  // It is safer to default to student being true, since this just results
  // in providing extra information to the user, which may not be relevant
  const [isStudent, setIsStudent] = useState(true);
  const [flip, setFlip] = useState(["show-card", "hide-card"]);

  // Get User data from API
  const {
    isSuccess: userIsSuccess,
    isError: userIsError,
    data: userData,
  } = useQuery("userProfile", () => axios.get(`${endpointApi}/profile`), {
    onError: (error: AxiosError) => {
      if (error?.response?.status === 401) {
        window.location.reload();
      }
    },
    onSuccess: (userData) => {
      // Since staff and faculty tend to keep student personas
      // if they were previously students, we need to check if a
      // user is currently an employee as well as checking for
      // the student personas
      setIsStudent(
        !Object.hasOwn(userData.data, "jobtitle") &&
          (userData.data.PERSONAS.includes("STU") ||
            userData.data.PERSONAS.includes("UG") ||
            userData.data.PERSONAS.includes("GR")),
      );
      const image = Buffer.from(userData.data.PHOTO.data).toString("base64");
      const imageSrc = `data:image/jpeg;base64,${image}`;
      setProfilePicture(imageSrc);
    },
    staleTime: Infinity,
  });

  // Get One Card Data from API
  const { isSuccess: patronIsSuccess, data: patronData } = useQuery(
    "onecard",
    () => axios.get(`${endpointApi}/onecard`),
    {
      onError: (error: AxiosError) => {
        if (error?.response?.status === 401) {
          window.location.reload();
        }
      },
      onSuccess: (patronData) => {
        // At a minimum, everyone will have a cougar bucks account
        if (patronData.data.cougarBucksBalance) {
          setHasAccount(true);
          // The most minimal dining plan has flex dollars
          if (patronData.data.flexDollarsBalance) {
            setHasDiningPlan(true);
          }
        }
      },
      staleTime: Infinity,
    },
  );

  // Set values if in dev mode
  if (devMode) {
    if (patronData) {
      patronData.data.cougarBucksBalance ??= cougBuck;
      patronData.data.mealsBalance ??= mealBal;
      patronData.data.guestMealsBalance ??= guestBal;
    }
  }

  // Function to handle the card flip and hides the card that is behind
  const flipVirtualID = () => {
    const card = $(".card-flip-animation-surface .card-contents");
    // If the card is currently not transformed, flip it by adding a transformation.
    // Otherwise, unflip it by removing the transformation.
    if (flip[0] === "show-card") {
      setFlip(["hide-card", "show-card"]);
      card.css({
        transform: "rotateY(180deg)",
      });
    } else {
      setFlip(["show-card", "hide-card"]);
      card.css({
        transform: "none",
      });
    }
  };

  // If the patron data has been succesfully loaded, generate the back of the VID
  if (patronIsSuccess) {
    displayBack = (
      <>
        <DisplayCard
          hasAccount={hasAccount}
          isStudent={isStudent}
          hasDiningPlan={hasDiningPlan}
          patronData={patronData}
        />
        <button
          id="hours-menu-button"
          className="button-sec"
          onClick={() => showHours(true)}
        >
          Hours / Menu
        </button>
      </>
    );
  } else {
    // Otherwise, just leave the back blank (this should be temporary, until the data is loaded)
    displayBack = <div className={`card-face, ${flip[1]}`} id="back" />;
  }

  // If there was an error fetching the user profile, return the oops
  const showFront = userData && !userIsError;

  if (!showFront) {
    return <OopsPage />;
  } else if (showFront) {
    return (
      <main id="virtual-id-one">
        <div className="card-flip-animation-surface">
          <div className="card-contents">
            <div className={`card-face ${flip[0]}`} id="front">
              <button id="card-button" onClick={() => flipVirtualID()}>
                <img id="icon-rotate" alt="Rotate Card" src={iconRotate} />
              </button>
              <CardFront
                userData={userData}
                userIsSuccess={userIsSuccess}
                profilePicture={profilePicture}
              />
            </div>
            <div className={`card-face ${flip[1]}`} id="back">
              <button id="card-button" onClick={() => flipVirtualID()}>
                <img id="icon-rotate" alt="Rotate Card" src={iconRotate} />
              </button>
              {displayBack}
            </div>
          </div>
        </div>

        <div id="modal-background" className="modal">
          <div id="hours-modal" className="modal-content">
            <button className="close-button" onClick={() => showHours(false)}>
              <div>&#xD7;</div>
            </button>
            <iframe
              src="https://apu.cafebonappetit.com/#cafe-hours"
              title="Cafe Hours and Menu"
              id="hours-iframe"
            />
          </div>
        </div>
        <div id="suicide-lifeline-section">
          <p id="suicide-lifeline-label">
            National Suicide Prevention Lifeline{" "}
          </p>
          <a id="suicide-lifeline-number" href="tel:988">
            Call: 988
          </a>
        </div>
      </main>
    );
  } else {
    return (
      <div className="cliploader-spinner">
        <ClipLoader color={"#700"} size={100} />
      </div>
    );
  }
};

export default VIDCard;
