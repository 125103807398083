//

import { TrolleyDataObject, TrolleyData } from "#types/trolley";

const foothillN = 34.1337;
const foothillS = 34.1333;
const citrusE = -117.8899;

const zone = [
  // East, Felix, Segerstrom, Soccer
  // 1 N Citrus: University to Foothill
  {
    NW: [foothillN, -117.8902],
    SE: [34.1308, citrusE],
  },
  // 2 Foothill: Palm to Citrus
  {
    NW: [foothillN, -117.8931],
    SE: [foothillS, citrusE],
  },
  // 3 Foothill: Odell to Palm
  {
    NW: [foothillN, -117.8942],
    SE: [foothillS, -117.8931],
  },
  // 4 Odell
  {
    NW: [34.1348, -117.8949],
    SE: [foothillN, -117.8939],
  },
  // 5 66: Soccer to Foothill
  {
    NW: [foothillN, -117.9001],
    SE: [foothillS, -117.8974],
  },
  // 6 Foothill: 66 to Odell
  {
    NW: [foothillN, -117.8974],
    SE: [foothillS, -117.8942],
  },
  // 7 University Dr
  {
    NW: [34.131, citrusE],
    SE: [34.1309, -117.8874],
  },
  // 8 Segerstom and Building 1 (short cut)
  {
    NW: [34.1341, -117.8962],
    SE: [foothillN, -117.8959],
  },
];

// Function to sum remaining stop ETA's
const sumETA = (locArray: Array<number>, stopNum: number) =>
  locArray.slice(stopNum, 4).reduce((acc: number, cur: number) => acc + cur, 0);

const getLastStop = (lastStatus: string) => {
  // What Number stop was lastStauts from status name
  let trolleyStop;
  switch (true) {
    case lastStatus.includes("East"):
      trolleyStop = lastStatus.includes("Arrive") ? [4, 3] : [0, 3];
      break;
    case lastStatus.includes("Felix"):
      trolleyStop = lastStatus.includes("Arrive") ? [1, 4] : [1, 0];
      break;
    case lastStatus.includes("Segerstrom"):
      trolleyStop = [2, 1];
      break;
    case lastStatus.includes("Soccer"):
      trolleyStop = [3, 2];
      break;
    default:
      trolleyStop = [0, 3];
  }
  return trolleyStop;
};

// Return true if number is between two numbers (order does not matter)
const isInRange = (num: number, range: number[]) =>
  (num - range[0]) * (num - range[1]) <= 0;

// Return what zone a trolley is in if at all
const getZoneNum = ([lat, lng]: number[]) => {
  let routeNumber = 0;
  // loop zone array for match
  zone.forEach(({ NW, SE }, route) => {
    if (isInRange(lat, [NW[0], SE[0]]) && isInRange(lng, [NW[1], SE[1]])) {
      routeNumber = route + 1;
    }
  });
  return routeNumber;
};

// Return ETA time adjustment
const getETAAdjustment = (lastStatus: string, lastLocation: number[]) => {
  // Determin direction
  const fromEast = lastStatus.includes("East");
  const fromFelix = lastStatus.includes("Felix");

  // Switch array of adjustments depending on where the trolley is coming from.
  // First num in array is "0" in case a route is not found so no adjustment is made
  let adjustArray;
  if (fromEast) {
    adjustArray = [0, -1, -3, -4, -5, 0, 0, 0, 0];
  } else if (fromFelix) {
    adjustArray = [0, -9, -8, -7, -5, -6, -7, -10, -6];
  } else {
    adjustArray = [0, -4, -3, -2, 0, -1, -2, -5, 0];
  }

  // Match Zone with Adjustment array
  return adjustArray[getZoneNum(lastLocation)];
};

// trolley ETA
const MapETA = (props: TrolleyData) => {
  // Trolley Stop Array Key
  // East = 0 (5min to Felix)
  // Felix = 1 (2min to Seg)
  // Seg = 2 (3min to Soc)
  // Soc = 3 (5min to East)
  // ETA Times in Minutes baseded on data from one trolley over one day
  // Felix is calculated by shifting the East array by one eg ([1,2,3] -> [2,3,1])
  // and keeping the "0" at the end
  const eastLoopMinETA = [5, 2, 3, 5, 0];
  const felixLoopMinETA = eastLoopMinETA
    .slice(1, 4)
    .concat([eastLoopMinETA[0], 0]);

  const trolleyETA = [];
  const eastETAArray: Array<number> = [];
  const westETAArray: Array<number> = [];

  props.trolleysData.forEach((data: TrolleyDataObject) => {
    const lastStatus = data.LastStatus;
    const lastLocation = [data.LastLocationLat, data.LastLocationLon];

    // What Number stop was lastStauts
    const trolleyStop = getLastStop(lastStatus);

    // Get the adjustment based on zone and directions
    const adjustETA = getETAAdjustment(lastStatus, lastLocation);

    // Add each trolly ETA for East and Felix to array
    eastETAArray.push(sumETA(eastLoopMinETA, trolleyStop[0]) + adjustETA);
    westETAArray.push(sumETA(felixLoopMinETA, trolleyStop[1]) + adjustETA);
  });

  // Get shortest ETA for East and Felix (handles multiple trolley's)
  eastETAArray.length > 0 && trolleyETA.push(Math.min(...eastETAArray));
  westETAArray.length > 0 && trolleyETA.push(Math.min(...westETAArray));

  return (
    <>
      {trolleyETA && trolleyETA.length > 0 ? (
        <div className="trolley-eta">
          <div>Trolley Stop Estimate</div>
          <hr />
          <div className="table">
            <div className="tr">
              <div className="align-left td">(1) East Arrival</div>
              <div className="align-right td">{trolleyETA[0] ?? "n/a"} min</div>
            </div>
            <div className="tr">
              <div className="align-left td">(2) Felix Arrival</div>
              <div className="align-right td">{trolleyETA[1] ?? "n/a"} min</div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MapETA;
